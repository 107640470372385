

export default class LeafletHelper{
    private _initConfig:any;
    private _keyField:string = "PrimKey";
    private _dataObject:any;
    private _config:any = {
        latField:"Latitude", 
        lngField:"Longitude",
        geoLocationField:"GeoLocation",
        title:"Name",
        description:"Title",
        saveOnchange:true,
        center:{
            lat: 59.59780273035881, 
            lng: 5.745103582012234
        },
        zoomControl: true,
        zoom: 50
    }

    

 
    constructor(pConfig:any,pDataObject:any){

        this._dataObject = pDataObject;
        this._initConfig = pConfig??{};

        if(pConfig && pConfig.geoLocationField){
            this._config.geoLocationField = pConfig.geoLocationField;
        }
        if(pConfig && pConfig.latField){
            this._config.latField = pConfig.latField;
        }
        if(pConfig && pConfig.lngField){
            this._config.lngField = pConfig.lngField;
        }
        if(pConfig && pConfig.title){
            this._config.title = pConfig.title;
        }
        if(pConfig && pConfig.description){
            this._config.description = pConfig.description;
        }
        if(pConfig && pConfig.hasOwnProperty('saveOnchange')){
            this._config.saveOnchange = pConfig.saveOnchange;
        }
        if(pConfig && pConfig.hasOwnProperty('center')){
            this._config.center = pConfig.center;
        }
        if(pConfig && pConfig.hasOwnProperty('zoomControl')){
            this._config.zoomControl = pConfig.zoomControl;
        }
        if(pConfig && pConfig.hasOwnProperty('zoom')){
            this._config.zoom = pConfig.zoom;
        }
        if(pDataObject){
            this._keyField = pDataObject.fields.uniqueField;
        }

    }

    getMapConfig(){
        const vConfig:any = {
            zoom:this._config.zoom,
            zoomControl:this._config.zoomControl,
            center:this._config.center,
        }

        Object.keys(this._initConfig).forEach(key=>{
            if(!this._config.hasOwnProperty(key)){
                vConfig[key] = this._initConfig[key];
            }
        })
       
        return vConfig;
    }


    getMarkerLocation(item:any){
         if(item){
            if(item.hasOwnProperty(this._config.geoLocationField)){
                if(item[this._config.geoLocationField]){
                    return JSON.parse(item[this._config.geoLocationField]);
                }
            } 
            if(item.hasOwnProperty(this._config.latField) && item.hasOwnProperty(this._config.lngField) && item[this._config.lngField]){
                return {
                    lng:item[this._config.lngField],
                    lat:item[this._config.latField]
                }
            }
        }
        return null;
    }

    getMarker(item:any){
        const vMarker:any = this.getMarkerLocation(item);
        if(!this._keyField){
            if(item.PrimKey){
                this._keyField = "PrimKey";
            }else if(item.ID){
                this._keyField = "ID";
            }
        }
        vMarker.id = item[this._keyField];
        const vPopup = this.getPopup(item);
        if(vPopup && vMarker){
            vMarker['message'] = vPopup;
        }

        return vMarker;
    }

    getPopup(item:any){
        let vPopup = '';
        if(item.hasOwnProperty(this._config.title)){
            vPopup += `<h6>${item[this._config.title]}</h6>`;
        }

        if(item.hasOwnProperty(this._config.description)){
            vPopup += item[this._config.description];
        }

        return vPopup;
    }

    async getMarkers(){
        const vReturn:Array<any> = [];
        if(this._dataObject)
        this._dataObject.data.forEach((item:any)=>{
            vReturn.push(this.getMarker(item));
        })

        return vReturn;


    }

    async getCenterLocation(item:any){
        if(item){
            const vMarkerLocation = this.getMarkerLocation(item);

            if(vMarkerLocation && vMarkerLocation.lat) return vMarkerLocation;
        }
        
        const pPos = await this._getCurrentPosition();


        return pPos;
    }

    async updateItemLocation(item:any,lat:number, lng:number){
        if(!item) return;
        if(item.hasOwnProperty(this._config.geoLocationField)){
            item[this._config.geoLocationField] = JSON.stringify({
                lng:lng,
                lat:lat
            })
        }else if(item.hasOwnProperty(this._config.latField) && item.hasOwnProperty(this._config.lngField)){
                item[this._config.latField] = lat;
                item[this._config.lngField] = lng;
        }else{
            console.warn("Unable to set location, location fields do not exists");
        }

        if(this._config.saveOnchange && item.save){
            item.save();
        }
    }

    async bulkRemove(pKeys:Array<any>){
        const vItems:Array<any> = [];

        pKeys.forEach(key=>{
            vItems.push(this._dataObject.storage.getItemByKey(key));
        })
        return await this._dataObject.recordSource.bulkDelete(vItems);
    }

     async updateLocation(key:string,pCoordinates:Array<number>){
        if(!key) return;
        const vFound = this._dataObject.storage.getItemByKey(key);
        if(vFound){
            return await this.updateItemLocation(vFound,pCoordinates[1],pCoordinates[0]);
        } else{
            return null;
        }
        
    }

    getTilesUrl(){
        let vReturn = "/api/osm/tiles/{z}/{x}/{y}";
        if(this._isBehindProxy()){
            return '/nt'+vReturn;
        }

        return vReturn;
    }

    async _getCurrentPosition(){
        return new Promise((resolve,reject)=>{
            if (!navigator.geolocation) resolve(this._config.defaultCenter);
            navigator.geolocation.getCurrentPosition((pPosition)=>{
                resolve({
                    lng:pPosition.coords.longitude,
                    lat:pPosition.coords.latitude
                });
            }, (error)=>{
                reject(error)
            }, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            });
        })
    
  
    }

    private _isBehindProxy() : boolean{
        return (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content === 'true';
    }

}






